import * as Yup from "yup";

export interface Myform {
  nome: string;
  email: string;
  telefone: string;
  msg: string;
}

export const initalValues: Myform = {
  nome: "",
  email: "",
  telefone: "",
  msg: "",
};

export const validation = Yup.object().shape({
  nome: Yup.string()
    .min(2, "Mínimo 2 caracteres")
    .required("DIGITE O NOME COMPLETO"),
  email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: Yup.string().required("Campo Obrigatório"),
  msg: Yup.string().required("Campo Obrigatório"),
});

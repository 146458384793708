import React from "react";
import Link from "next/link";
import Image from "next/image";
import { useSite } from "../../hooks/useSite";
import Form from "./form";
import { Container, ContainerModal } from "./styles";

export default function Orcamento() {
  const { handleClose, config, modal } = useSite();

  return (
    <ContainerModal className={`${modal.orcamento ? "active" : ""}`}>
      <Container className="container-modal">
        <div className="container-title">
          <h3>Solicitar Orçamento</h3>
          <button onClick={() => handleClose("orcamento")}>
            <Image
              src="/img/icone/close.svg"
              alt="fechar"
              width={14}
              height={14}
            />
          </button>
        </div>

        <div className="container-text">
          <p>Envie sua mensagem preenchendo os campos abaixo</p>
        </div>

        <Form />

        <div className="contato-social">
          <div className="social">
            <Link
              href={config?.social?.instagram || ""}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                src="/img/icone/insta-footer.svg"
                width={19}
                height={19}
                alt="instagram"
              />
            </Link>
            <Link
              href={config?.social?.facebook || ""}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image
                src="/img/icone/face-footer.svg"
                width={11}
                height={19}
                alt="Facebook"
              />
            </Link>
          </div>
        </div>
      </Container>
    </ContainerModal>
  );
}

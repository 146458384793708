import styled from "styled-components";

export const Container = styled.div`
  padding: 75px 75px 176px 72px;
  width: 483px;
  height: 100vh;
  transition: all 0.5s;
  color: ${(props) => props.theme.colors.secondarydark};
  background-color: ${(props) => props.theme.colors.secondary};
  &.active {
    right: 0px;
    transition: all 0.5s;
  }
  .container-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: 0.02em;
    }
    img {
      cursor: pointer;
    }
  }

  .container-text {
    margin-bottom: 47px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.04em;
    }
  }

  .contato-social {
    margin-top: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
    }

    .social {
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (max-width: 1366px) {
    padding-bottom: 75px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding-right: 10vw;
    padding-left: 10vw;
  }
`;

export const StyleForm = styled.div`
  form {
    .container-form {
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.04em;
      }
      input,
      textarea {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: ${(props) => props.theme.colors.contrast};
        background-color: transparent;
        border-bottom: 1px solid ${(props) => props.theme.colors.tertiary};
        padding-left: 0px;
        border-radius: 0px;
        transition: all 0.5s;
        &:focus {
          transition: all 0.5s;
          border-bottom: 1px solid ${(props) => props.theme.colors.tertiary};
          &::placeholder {
            color: ${(props) => props.theme.colors.tertiary};
            transition: all 0.5s;
          }
        }

        &:hover {
          border-bottom: 1px solid ${(props) => props.theme.colors.tertiary};
          transition: all 0.5s;
          &::placeholder {
            transition: all 0.5s;
          }
        }

        /* &:valid {
					transition: all 0.5s;
					&::placeholder {
						transition: all 0.5s;
					}
				} */

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 140%;
          letter-spacing: 0.04em;
          color: #00ffb278;
          transition: all 0.5s;
        }
      }
    }

    button {
      margin-top: 50px;
      background: transparent;
    }
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: -1;
  transition: all 0.2s;
  &.active {
    z-index: 999;
    overflow-y: scroll;
    .container-modal {
      right: 0vw;
      transition: all 0.2s;
    }
  }
  .container-modal {
    position: absolute;
    top: 0px;
    right: -100vw;
    transition: all 0.2s;
  }
`;

import React from "react";
import Image from "next/image";

import InputMask from "react-input-mask";
import { Form, Field, Formik, ErrorMessage, FormikProps } from "formik";

import { StyleForm } from "./styles";
import { Myform, initalValues, validation } from "./config";

export default function Formulario() {
  return (
    <StyleForm>
      <Formik
        enableReinitialize
        initialValues={initalValues}
        validationSchema={validation}
        onSubmit={(value, actions) => {
          console.log(value);
        }}
      >
        {(props: FormikProps<Myform>) => {
          const { handleChange, values } = props;
          const { telefone } = values;

          return (
            <Form>
              <div className="container-form user">
                <Field
                  name="nome"
                  type="text"
                  autoComplete="new-password"
                  placeholder="Nome"
                />
                <ErrorMessage component="span" name="nome" />
              </div>

              <div className="container-form user">
                <Field
                  name="email"
                  type="text"
                  autoComplete="new-password"
                  placeholder="E-Mail"
                />
                <ErrorMessage component="span" name="email" />
              </div>

              <div className="container-form">
                <InputMask
                  mask="(99)99999-9999"
                  placeholder="Telefone"
                  value={telefone}
                  onChange={handleChange}
                  type="tel"
                  name="telefone"
                ></InputMask>
                <ErrorMessage component="span" name="telefone" />
              </div>

              <div className="container-form user">
                <Field name="msg" component="textarea" placeholder="Mensagem" />
                <ErrorMessage component="span" name="msg" />
              </div>

              <button>
                <Image
                  src="/img/icone/seta-verde-dir.svg"
                  width={43}
                  height={43}
                  alt="seta dir"
                />
              </button>
            </Form>
          );
        }}
      </Formik>
    </StyleForm>
  );
}
